import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import { theme } from "../utils/theme"
import {Grid, FormControl, MenuItem, Select} from '@material-ui/core';
import {Link} from 'gatsby';
import { navigate } from 'gatsby-link'
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import templates from '../components/get-in-touch/templates';

gsap.registerPlugin(ScrollToPlugin)



const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: visible;
`

const TitleCond = styled.h5`
  font-size: 3rem;
  font-weight: 300;
  width: 300px;
  line-height: 1.1;
  color: ${props => props.theme.colors.body};
  margin-bottom: 70px;
  text-align: left;
  padding-left: 80px;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
    margin: 5rem 0 5rem 0;

    &.template-cond {
      margin-bottom: 1rem;
    }
  }

`

const StorySection = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  text-align: center;

  @media ${props => props.theme.breaks.down('md')} {
    .title-cond {
      width: 90% !important;
    }
  }
`

const TemplateSection = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  text-align: center;
  position: relative;

  @media ${props => props.theme.breaks.down('md')} {
    .title-cond {
      width: 90% !important;
    }

  }
`

const StoryContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  position: relative;
  padding-left: 80px;

  .select {
    margin-top: 60px;
  }

  h1 {
    color: ${props => props.theme.colors.primary};
    font-size: 10rem;
    width: 60%;
    margin: 0 auto;
    text-align: left;

    @media ${props => props.theme.breaks.down('lg')} {
      font-size: 8rem;
    }

    @media ${props => props.theme.breaks.down('md')} {
      font-size: 5rem;
      width: 90%;
      margin-left: 0;
    }
  }


  h5 {
    color: ${props => props.theme.colors.primary};
    text-align: left;
    margin-bottom: 30px;
  }

  &.template-story {
    margin-top: 10rem;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
    padding-left: 0;
    margin-top: 15%;

    &.template-story {
      margin-top: 5rem;
    }
  }
  
`

const Form = styled.form`

  .MuiSelect-filled {
    background-color: ${props => props.theme.colors.body};
    color: ${props => props.theme.colors.dark} !important;
    font-size: 2.5rem;
    text-align: left;
    font-weight: 500;

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 1.8rem;
    }
  }

  .MuiSelect-filled:focus {
    background-color: ${props => props.theme.colors.body};
    color: ${props => props.theme.colors.dark} !important;
  }

  .MuiFilledInput-input {
    padding: 20px 12px 15px 50px;
  }

  .MuiSelect-iconFilled {
    fill: ${props => props.theme.colors.dark};
  }

`

const TemplateContainer = styled.div`
  position: relative;
  width: 100%;

  p {
    color: ${props => props.theme.colors.primary};
    font-weight: 300;
    text-align: left;
    line-height: 1.3;
    font-size: 2.2rem;
    margin-bottom: 20px;
  }

  .inline-input {
    background-color: transparent;
    border: none;
    border-bottom: 1px dashed ${props => props.theme.colors.primary};
    padding: 0 5px 5px 20px;
    color: ${props => props.theme.colors.primary};
    font-weight: 300;
  }

  .para {
    text-align: left;
    display: block;
    margin:10px 0;
    width: 50%;
    font-weight: 300;
    padding: 5px;

    @media ${props => props.theme.breaks.down('sm')} {
      width: 100%;
    }
  }

  .submit-button {
    margin-top: 50px;
  }
`

const FormTemplate = styled.div`
  /* position: absolute; */
  width: 85%;
  /* left: 0;
  top: 20px; */

  /* z-index: ${props => props.status ? 80 : 1}; */
  display: ${props => props.status ? 'block' : 'none'};

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    margin-bottom: 100px;
  }
`
const Label = styled.h5`
  color: ${props => props.theme.colors.primary};
  font-weight: 300;
  margin-bottom: 5px;
  text-align: left;
`

const Button = styled.button`
  display: block;
  text-align: center;
  background-color: ${props => props.theme.colors.body};
  color: ${props => props.theme.colors.dark};
  width: 96%;
  border: none;
  padding: 15px 0;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.bodyLight};
  }
`

const FileButton = styled.label`
  display: block;
  margin: 30px 0;
  width: 350px;
  padding: 20px;
  text-align: center;
  background-color: ${props => props.theme.colors.body};
  transition: all .3s;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: ${props => props.theme.colors.bodyLight};
  }

  .file-name {
    position: absolute;
    left: 105%;
    bottom: 5px;
    font-weight: 300;
    color: ${props => props.theme.colors.primary};
    display: inline-block;
    width: 200px;
    text-align: left;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    width: 100%;
  }
`


const FixedFooter = styled.div`
  height: 100px;
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  margin: 60px auto 0 auto;
  padding-left: 40px;
  position: fixed;
  background-color: ${props => props.theme.colors.dark};
  bottom: 0;
  z-index: 90;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
    bottom: 0;
  }
`

const AbsoluteFooter = styled.div`
  height: 100px;
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  /* margin: 60px auto 0 auto; */
  padding-left: 40px;
  position: relative;
  background-color: ${props => props.theme.colors.dark};
  margin-top: 80px;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
    bottom: 0;
  }
`


const GetInTouchPage = ({ data }) => {

  function encode(data) {
    const formData = new FormData()
  
    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }
  
    return formData
  }

  const [state, setState] = useState({dropdown: '', attachment: {}});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
    gsap.to(window, {scrollTo: '.template-sec', duration: .4});
  }

  const handleAttachment = (e) => {
    setState({ ...state, [e.target.name]: e.target.files[0] })
    // console.log(e.target.files[0].name);
  }
  

  const onSubmit =  (e) => {
    e.preventDefault();
    let finalText = templates[state.dropdown].text
    templates[state.dropdown].inputs.forEach((cur, i) => {
      finalText = finalText.replace(`%i${i + 1}`, `'${state[cur]}'`);
    })
    finalText = finalText.replace('%para', `'${state.paragraph}'`)

    const form = e.target
    fetch('/.netlify/functions/multipart-file-upload', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
        'paragraph': finalText
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    
      <Layout color={theme.colors.body} pageTitle="Get in touch.">
      <Container>

      <Form
       name="template-upload"
       method="post"
       action="/submitted"
       data-netlify="true"
       data-netlify-honeypot="bot-field"
       onSubmit={onSubmit}>

        <input type="hidden" name="form-name" value="template-upload" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        
        <StorySection className="point-sec">
          <div className="title-cond" style={{width: '70%', margin: '0 auto'}}>
          <TitleCond>Get In touch</TitleCond>
          </div>

          <StoryContainer>
            <h1>Speak to celebrand about:</h1>

           
            <Grid container justify="center" className="select">
              <Grid item md={7} xs ={12}>
                <Label>Select Topic</Label>
              <FormControl variant="filled" fullWidth >
                <Select
                  name="dropdown"
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={state.dropdown}
                  onChange={handleChange}
                  placeholder="Select"
                >
                  <MenuItem value="Branding">Branding</MenuItem>
                  <MenuItem value="Hiring">Hiring</MenuItem>
                  <MenuItem value="Feedback">Feedback</MenuItem>
                  <MenuItem value="Speaking Engagements">Speaking Engagements</MenuItem>
                  <MenuItem value="A Topic Not on This List"> A Topic Not on This List
                </MenuItem>
                </Select>
              </FormControl>
              </Grid>
            </Grid>

           

          </StoryContainer>
          
        
         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>

         </StorySection>

         <TemplateSection className="template-sec">
          <div className="title-cond" style={{width: '70%', margin: '0 auto'}}>
          <TitleCond className="template-cond">{state.dropdown}</TitleCond>
          </div>

          <StoryContainer className="template-story">

            <TemplateContainer>


              <FormTemplate status={state.dropdown === 'Branding' ? true : false}>
              <p>Dear Celebrand,</p>

              <p>My name is <input className="inline-input" name="name" type="text" value={state.name || ''} onChange={handleChange} />, my business is called <input className="inline-input" name="business" type="text" value={state.business || ''} onChange={handleChange} />, and I’m interested in your branding services. My biggest problem in business right now is <input className="inline-input" name="problem" value={state.problem || ''} onChange={handleChange} type="text" />, and I’d like to know how branding can help.</p>

              <p>Here’s some more information that you might find relevant:</p> <textarea className="para" rows={4} name="paragraph" value={state.paragraph || ''} onChange={handleChange} required></textarea>

              <p>Thank you for your time. You can respond to my email at <input className="inline-input" name="email" value={state.email || ''} onChange={handleChange} type="email" required/>.</p>

              <Grid className="submit-button" container spacing={5} justify="center">
                <Grid item xs={12}>
                  <Button>Submit</Button>
                </Grid>
              </Grid>
              </FormTemplate>


              <FormTemplate status={state.dropdown === 'Hiring' ? true : false}>
              <p>Dear Celebrand,</p>

              <p>My name is <input className="inline-input" name="name" type="text" value={state.name || ''} onChange={handleChange} />, and I’m interested in joining your team. I’d be interested in working as a/an <input className="inline-input" name="position" type="text" value={state.position || ''} onChange={handleChange} />. Let me tell you about my relevant experience:</p>
              <textarea className="para" rows={4} name="paragraph" value={state.paragraph || ''} onChange={handleChange} required></textarea>

              <p>Attached, you’ll find <input className="inline-input" name="document" type="text" value={state.document || ''} onChange={handleChange} />, which is a relevant document or set of documents to show you why I’d be a good fit. </p>

              <FileButton file={state.attachment} htmlFor="import-button">
                <input
                    id="import-button"
                    name="attachment"
                    // inputProps={{
                    //   accept:
                    //     ".csv",
                    // }}
                    onChange={handleAttachment}
                    style={{display: "none"}}
                    type="file"
                />
                <span>Upload resume/cover letter</span>
                <span className="file-name">{state.attachment.name || ''}</span>
            </FileButton>

              <p>Thank you for your time. You can respond to my email at <input className="inline-input" name="email" value={state.email || ''} onChange={handleChange} type="email" required/>.</p>

              <Grid className="submit-button" container spacing={5} justify="center">
                <Grid item xs={12}>
                  <Button>Submit</Button>
                </Grid>
              </Grid>
              </FormTemplate>



              <FormTemplate status={state.dropdown === 'Speaking Engagements' ? true : false}>
              <p>Dear Celebrand,</p>

              <p>My name is <input className="inline-input" name="name" type="text" value={state.name || ''} onChange={handleChange} />, and I’m interested in speaking to your team about speaking engagements. Seeing as branding requires a lot of knowledge in the areas of design, copy, presentation, and the like, I thought I’d reach out to see if somebody at Celebrand would be able to speak about <input className="inline-input" name="subject" type="text" value={state.subject || ''} onChange={handleChange} /> at <input className="inline-input" name="location" type="text" value={state.location|| ''} onChange={handleChange}/>, an event I am involved in.</p>

              <p>Here’s some more information that you might find relevant:</p> <textarea className="para" rows={4} name="paragraph" value={state.paragraph || ''} onChange={handleChange} required></textarea>

              <p>Thank you for your time. You can respond to my email at <input className="inline-input" name="email" type="email" value={state.email || ''} onChange={handleChange} required />.</p>

              <Grid className="submit-button" container spacing={5} justify="center">
                <Grid item xs={12}>
                  <Button>Submit</Button>
                </Grid>
              </Grid>
              </FormTemplate>


              <FormTemplate status={state.dropdown === 'Feedback' ? true : false}>
              <p>Dear Celebrand,</p>

              <p>My name is <input className="inline-input" name="name" type="text" value={state.name || ''} onChange={handleChange} />, and I wanted to tell you what I think about your work.</p> 
              <p>Here’s what I have to say:</p> 
              <textarea className="para" rows={4} name="paragraph" value={state.paragraph || ''} onChange={handleChange} required></textarea>

              <p>Thank you for your time. You can respond to my email at <input className="inline-input" name="email" type="email" value={state.email || ''} onChange={handleChange} required />.</p>

              <Grid className="submit-button" container spacing={5} justify="center">
                <Grid item xs={12}>
                  <Button>Submit</Button>
                </Grid>
              </Grid>
              </FormTemplate>


              <FormTemplate status={state.dropdown === 'A Topic Not on This List' ? true : false}>
              <p>Dear Celebrand,</p>

              <p>My name is <input className="inline-input" name="name" type="text" value={state.name || ''} onChange={handleChange} />, and my topic wasn’t on your list.</p>
              
              <p>Here’s what I have to say:</p>
              <textarea className="para" rows={4} name="paragraph" value={state.paragraph || ''} onChange={handleChange} required></textarea>

              <p>Thank you for your time. You can respond to my email at <input className="inline-input" name="email" type="email" value={state.email || ''} onChange={handleChange} required />.</p>

              <Grid className="submit-button" container spacing={5} justify="center">
                <Grid item xs={12}>
                  <Button>Submit</Button>
                </Grid>
              </Grid>
              </FormTemplate>

            </TemplateContainer>

           

          </StoryContainer>
          
        
         {/* <AbsoluteFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </AbsoluteFooter> */}

         </TemplateSection>

      </Form>
        
      </Container>
      </Layout>
    
)
}

export default GetInTouchPage;