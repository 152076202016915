const templates = {
  'Branding': {
    inputs: ["name", "business", "problem", "email"], 
    text: `
    Dear Celebrand,
    My name is %i1, my business is called %i2, and I’m interested in your branding services. My biggest problem in business right now is %i3, and I’d like to know how branding can help.

    Here’s some more information that you might find relevant:
    %para 

    Thank you for your time. You can respond to my email at %i4.
      `
    },
  'Hiring': {
    inputs: ["name", "position", "document", "email"], 
    text: `
    Dear Celebrand,
    My name is %i1, and I’m interested in joining your team. I’d be interested in working as a/an %i2. Let me tell you about my relevant experience:
    %para 

    Attached, you’ll find %i3, which is a relevant document or set of documents to show you why I’d be a good fit.
    
    Thank you for your time. You can respond to my email at %i4.
      `
    },
  'Feedback': {
    inputs: ["name", "email"], 
    text: `
    Dear Celebrand,
    My name is %i1, , and I wanted to tell you what I think about your work.
    
    Here’s what I have to say: 
    %para

    Thank you for your time. You can respond to my email at %i2.
      `},
  'A Topic Not on This List': {
    inputs: ["name", "email"], 
    text: `
    Dear Celebrand,
    My name is %i1, and my topic wasn’t on your list.

    Here’s what I have to say:
    %para 

    Thank you for your time. You can respond to my email at %i2.
      `
    },
  'Speaking Engagements': {
    inputs: ["name", "subject", "location", "email"], 
    text: `
    Dear Celebrand,
    My name is %i1, and I’m interested in speaking to your team about speaking engagements. Seeing as branding requires a lot of knowledge in the areas of design, copy, presentation, and the like, I thought I’d reach out to see if somebody at Celebrand would be able to speak about %i2 at %i3, an event I am involved in.

    Here’s some more information that you might find relevant:
    %para 

    Thank you for your time. You can respond to my email at %i4.
      `
    },
  };

export default templates;

